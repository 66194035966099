<template>
  <div class="footer">
    <div class="logo"></div>

    <div class="links">
      <div
        v-for="(v, k) in links"
        :key="k"
        class="link-section"
        :class="[$mq == 'mobile' && 'pb20']"
      >
        <h5>{{ k }}</h5>
        <a :href="link" v-for="(link, name) in v" :key="name">
          <p class="link">{{ name }}</p>
        </a>
      </div>
    </div>
    <div :class="[$mq == 'mobile' && 'vertical']" class="flex wrap ml20">
      <div class="fs11 contact">
        <div class="flex items-center mb8">
          <i style="margin-right: 8px;" class="mr12 mail icon"></i>
          {{ $bus.siteData.contactEmail }}
        </div>
        <i class="phone icon"></i> {{ $bus.siteData.contactPhone }}
      </div>
      <p
        :class="[$mq == 'mobile' ? 'mt12' : 'mlauto mtauto mr20']"
        class="copyright"
      >
        Copyright © 2022 - Ethos India
      </p>
    </div>
  </div>
</template>

<style lang="css" scoped>
.copyright {
  color: white;
  font-size: 12px;
}
.contact {
  color: #fff;
  bottom: 84px;
  left: 24px;
}
.footer {
  background: #252525;
  background-size: cover;
  background-position: center;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 30px;
}
@media (max-width: 800px) {
  .footer {
    width: 100%;
  }
}
.logo {
  width: 70px;
  height: 30px;
  position: absolute;
  top: 24px;
  left: 24px;
  background: url("../assets/white_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.phone.icon {
  transform: scaleX(-1);
}
.links {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  width: 60%;
  padding-top: 40px;
}
.link-section {
  margin: 0px 24px;
}
.link-section h5 {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
@media (max-width: 800px) {
  .link-section {
    width: 50%;
  }
  .links {
    width: 100%;
    margin-top: 80px;
  }
}
.link {
  cursor: pointer;
  color: #d8d8d8;
  font-size: 12px;
  margin-top: 14px;
}
</style>

<script>
export default {
  name: "site-footer",
  data() {
    return {
      links: {
        "ABOUT US": {
          Home: "/",
          "Our Story": "/about",
          Partners: "/partners"
        },
        Platforms: {
          Ideace: "/ideace",
          Connectaid: "/connectaid",
          Acedge: "/acedge",
          Arcause: "/arcause"
        },
        // 'Resources': {
        //   'Blog': '',
        //   'Videos': '',
        //   'e-Books': ''
        // },
        Contact: {
          "T&C": "",
          "Privacy Policy": "/privacy-policy"
        }
      }
    };
  }
};
</script>
