import st from 'storerestapi'
import moment from 'moment'
import { Notyf } from 'notyf'
import universities from './universities'
let bus = {
  mixins: [universities],
  data () {
    return {
      user: st.getVar('user'),
      token: st.getVar('token'),
      siteData: {}
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.token
    },
    headers: function () {
      return {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
    },
    profileComplete: function () {
      /* eslint-disable */
      let user = this.user
      return this.checkProfileCompletion(user)
    }
  },
  methods: {
    redirect: function (link, newTab = false, justTab = false, title="") {
      if (newTab) {
        var win = window.open('','UATRpt', 'menubar=0,location=0,toolbar=0,resizable=1,status=1,scrollbars=1');
        if(win.document) { 
          win.document.write(`
            <html>
              <head>
                <title>${title}</title>
              </head>
              <body height="100%" width="100%">
                <iframe frameBorder="0" src="${link}#toolbar=0" height="100%" width="100%"></iframe>
              </body>
            </html>`);
        }
        return
      }
      if (justTab) {
        window.open(link, '_blank')
        return
      }
      window.location.replace(link);
    },
    validateEmail: function (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    processForm: function (f, unis, yogeq='') {
      let form = {...f}
      let names = unis.map(u => u.name).join(' ')

      // if (form['country']) {
      //   delete form['country']
      //   let cities = institutes.map(u => u.city)
      //     .filter((v, i, s) => s.indexOf(v) == i)
      //     .filter(city => {
      //       return !names.includes(city) || city == 'Guwahati'
      //     })
      //   form.push({
      //     institute
      //   })
      //   for (let c of cities) {
      //     form.push({

      //     })
      //   }
      //   return
      // } else {
      //   delete form['country']
      // }

      if (yogeq && form['yearOfGraduation']) { // Year of graduation equality
				let val = parseInt(form['yearOfGraduation'])
				delete form['yearOfGraduation']
				form['yearOfGraduation' + yogeq] = val
				form['yearOfGraduation_nin'] = [0, null]
			}

      if (form['institute']) {
        let uni = unis.find(u => u.name + ', ' + u.city == form['institute'])
        form['institute_contains'] = uni.name
        form['institute_containss'] = uni.city
        // form['institute_contains'] = uni.name
        // form['institute_contains'] = uni.city
        delete form['institute']
        delete form['city']
        delete form['state']
        delete form['zone']
      }
      if (form['city']) {
        form['_or'] = (form['_or'] || [])
        form['_or'].push({
          institute_contains: form['city']
        }, {
          location_contains: form['city']
        })
        delete form['city']
        delete form['state']
        delete form['zone']
      }
      if (form['state'] || form['zone']) {
        if (form['state']) {
          var institutes = unis.filter(u => u.state == form['state'])
        } else {
          institutes = unis.filter(u => u.zone == form['zone'])
        }
        let cities = institutes.map(u => u.city)
          .filter((v, i, s) => s.indexOf(v) == i)
          .filter(city => {
            return !names.includes(city) || city == 'Guwahati'
          })
        let us = institutes.map(u => u.name)
        
        us = us.filter((v, i, s) => {
          let named = unis.filter(u => u.name == v)
          return named.length <= 1
        }) // Filtering institutes that exist within multiple cities

        form['_or'] = (form['_or'] || [])
        for (let c of cities) {
          form['_or'].push({
            institute_contains: c
          }, {
            notListedInstitute_contains: c
          }, {
            location_contains: c
          })
        }
        for (let u of us) {
          form['_or'].push({
            institute_contains: u
          }, {
            notListedInstitute_contains: u
          })
        }
        delete form['state']
        delete form['zone']
      }
      return form
    },
    parseDate: function (str, format='', toFormat='Do MMM YY') {
      return moment(str, format).format(toFormat)
    },
    notify: function (str, type='success', position={x: 'right', y: 'top'}) {
      let notyf = new Notyf({position})
      notyf[type](str)
    },
    random: function () {
      return Math.random().toString(36).replace('0.', '')
    },
    similarity(s1, s2) {
      var longer = s1;
      var shorter = s2;
      if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
      }
      var longerLength = longer.length;
      if (longerLength == 0) {
        return 1.0;
      }
      return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
    },
    colorShade: function (p,c0,c1,l) {
      let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
      if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
      if(!this.pSBCr)this.pSBCr=(d)=>{
          let n=d.length,x={};
          if(n>9){
              [r,g,b,a]=d=d.split(","),n=d.length;
              if(n<3||n>4)return null;
              x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
          }else{
              if(n==8||n==6||n<4)return null;
              if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
              d=i(d.slice(1),16);
              if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
              else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
          }return x};
      h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
      if(!f||!t)return null;
      if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
      else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
      a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
      if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
      else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
    },
    editDistance(s1, s2) {
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();
    
      var costs = new Array();
      for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
          if (i == 0)
            costs[j] = j;
          else {
            if (j > 0) {
              var newValue = costs[j - 1];
              if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                  costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
        if (i > 0)
          costs[s2.length] = lastValue;
      }
      return costs[s2.length];
    },
    logout: function () {
      this.user = undefined
      this.token = undefined
      st.deleteVar('user')
      st.deleteVar('token')
      this.$emit('redirectToAuth')
    },
    getRegion: function (institute) {
      let universities = this.archUniversities
      let uni = universities.find(uni => uni.College == institute)
      return uni ? uni.Region : 'Not Specified'
    },
    getLatestRoles: async function () {
      let roles = await this.$req.get('website-roles', {
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      }).catch(e => console.log(e))
      return roles.data
    },
    checkProfileCompletion: function (user) {
      var fields = ['name', 'contact']
      if (user.type == 'Student') {
        fields.push('levelOfEducation')
        if (!['Middle School', 'High School'].includes(user.levelOfEducation)) {
          fields.push(...['yearOfGraduation', 'field'])
          if (!user.country || user.country == 'India') {
            fields.push('institute')
            if (user.institute == 'Not Listed') {
              fields.push('notListedInstitute')
            }
          }
        }
      } else if (user.type == 'Professional') {
        fields.push('employmentStatus')
        if (user.employmentStatus != 'None') {
          fields.push(...['field', 'companyName'])
          if (!user.country || user.country == 'India') {
            fields.push('location')
          }
        }
      } else if (user.type == 'Faculty') {
        fields.push(...['field'])
        if (!user.country || user.country == 'India') {
          fields.push('institute')
          if (user.institute == 'Not Listed') {
            fields.push('notListedInstitute')
          }
        }
      } else { return false }
      for (var f of fields) {
        if (!user[f]) {
          return false
        }
      }
      return true
    }
  }
}

export default bus