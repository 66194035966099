import Vue from 'vue'
import VueMq from 'vue-mq'
import axios from 'axios'
import VueLazyLoad from 'vue-lazyload'
import App from './App.vue'
import router from './router'
import bus from './bus'
import feather from 'vue-icon'
import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
import {
  setupCache
} from 'axios-cache-adapter'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

// Event Bus
Vue.prototype.$bus = new Vue(bus)
const DOMAIN = "https://ethosempowers.com"
Vue.prototype.$req = axios.create({
  baseURL: `${DOMAIN}/api`
})

Vue.prototype.$baseReq = axios.create({
  baseURL: DOMAIN
})

localforage.defineDriver(memoryDriver).then(() => {
  // Create `localforage` instance
  const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      memoryDriver._driver
    ],
    // Prefix all storage keys to prevent conflicts
    name: 'request-cache'
  })

  const cache = setupCache({
    maxAge: 5 * 60 * 1000, // 5 minute cache
    store: forageStore
  })

  // API proxy
  Vue.prototype.$req = axios.create({
    baseURL: `${DOMAIN}/api`,
    adapter: cache.adapter
  })

  Vue.prototype.$baseReq = axios.create({
    baseURL: DOMAIN,
    adapter: cache.adapter
  })
})


Vue.use(VueMeta)
Vue.use(feather, 'v-icon')
Vue.use(VueLazyLoad)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 800,
    tablet: 1200,
    desktop: Infinity
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
